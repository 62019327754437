<template>
  <v-container grild-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm12>
        <br />
        <div class='headline font-weight-medium'>
          Treatments
        </div>
        <br />
        <v-divider></v-divider>
        <br />
      </v-flex>

      <v-flex xs12 sm6 lg6>
        <div class='subheading font-weight-medium'>
          Allergy to drugs
        </div>
        <v-text-field
          label='Drugs:'
          v-model='treatments.drugAllergy'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <div class='subheading font-weight-medium'>
          Surgery history
        </div>
        <v-text-field
          label='Surgery:'
          v-model='treatments.surgery'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <div class='subheading font-weight-medium'>
          Medication history
        </div>
        <v-text-field
          label='BP:'
          v-model='treatments.medication.bp'
        ></v-text-field>
        <v-text-field
          label='Diabetes:'
          v-model='treatments.medication.db'
        ></v-text-field>
        <v-text-field
          label='Thyroid:'
          v-model='treatments.medication.th'
        ></v-text-field>
        <v-text-field
          label='Others:'
          v-model='treatments.medication.others'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <div class='subheading font-weight-medium'>
          Dental workups
        </div>
        <v-list>
          <v-list-item>
            <v-checkbox
              v-model='treatments.dental.choices'
              label='Root canal'
              value='Root canal'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model='treatments.dental.choices'
              label='Mercury filling'
              value='Mercury filling'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model='treatments.dental.choices'
              label='Amalgams'
              value='Amalgams'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-text-field
              label='Others:'
              v-model='treatments.dental.others'
            ></v-text-field>
          </v-list-item>
        </v-list>
      </v-flex>

      <v-flex sm12>
        <br />
        <div class='headline font-weight-medium'>
          Family history
        </div>
        <br />
        <v-divider></v-divider>
        <br />
      </v-flex>

      <v-flex xs12 sm6 lg6>
        <div class='subheading font-weight-medium'>
          Mother
        </div>
        <v-text-field
          label='Major illness:'
          v-model='family.mother.illness'
        ></v-text-field>
        <v-text-field
          label='Allergies:'
          v-model='family.mother.allergies'
        ></v-text-field>
        <v-text-field
          label='Surgery:'
          v-model='family.mother.surgery'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <div class='subheading font-weight-medium'>
          Father
        </div>
        <v-text-field
          label='Major illness:'
          v-model='family.father.illness'
        ></v-text-field>
        <v-text-field
          label='Allergies:'
          v-model='family.father.allergies'
        ></v-text-field>
        <v-text-field
          label='Surgery:'
          v-model='family.father.surgery'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <div class='subheading font-weight-medium'>
          Grandparents
        </div>
        <v-text-field
          label='Major illness:'
          v-model='family.grandparents.illness'
        ></v-text-field>
        <v-text-field
          label='Allergies:'
          v-model='family.grandparents.allergies'
        ></v-text-field>
        <v-text-field
          label='Surgery:'
          v-model='family.grandparents.surgery'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <div class='subheading font-weight-medium'>
          Siblings
        </div>
        <v-text-field
          label='Major illness:'
          v-model='family.siblings.illness'
        ></v-text-field>
        <v-text-field
          label='Allergies:'
          v-model='family.siblings.allergies'
        ></v-text-field>
        <v-text-field
          label='Surgery:'
          v-model='family.siblings.surgery'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <div class='subheading font-weight-medium'>
          Aunts/Uncles
        </div>
        <v-text-field
          label='Major illness:'
          v-model='family.uncleAunt.illness'
        ></v-text-field>
        <v-text-field
          label='Allergies:'
          v-model='family.uncleAunt.allergies'
        ></v-text-field>
        <v-text-field
          label='Surgery:'
          v-model='family.uncleAunt.surgery'
        ></v-text-field>
      </v-flex>

      <v-flex sm12>
        <br />
        <div class='headline font-weight-medium'>
          Habits
        </div>
        <br />
        <v-divider></v-divider>
        <br />
      </v-flex>

      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Smoking
        </div>
        <v-list>
          <v-list-item>
            <v-checkbox
              label='Beedi'
              value='Beedi'
              v-model='habits.smoking.choices'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              label='Cigarette'
              value='Cigarette'
              v-model='habits.smoking.choices'
              color='primary'
            />
          </v-list-item>
        </v-list>
        <v-text-field
          label='Per day:'
          v-model='habits.smoking.daily'
        ></v-text-field>
        <v-text-field
          label='Started since (years):'
          v-model='habits.smoking.start'
        ></v-text-field>
        <v-text-field
          label='Stopped since (years):'
          v-model='habits.smoking.stopped'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Alcohol drinking
        </div>
        <v-list>
          <v-list-item>
            <v-checkbox
              label='Beer'
              value='Beer'
              v-model='habits.alcohol.choices'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              label='Wine'
              value='Wine'
              v-model='habits.alcohol.choices'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              label='Liquor'
              value='Liquor'
              v-model='habits.alcohol.choices'
              color='primary'
            />
          </v-list-item>
        </v-list>
        <v-text-field
          label='Per day (ounce):'
          v-model='habits.alcohol.daily'
        ></v-text-field>
        <v-text-field
          label='Started since (years):'
          v-model='habits.alcohol.start'
        ></v-text-field>
        <v-text-field
          label='Stopped since (years):'
          v-model='habits.alcohol.stopped'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Travel extensively
        </div>
        <v-list>
          <v-list-item>
            <v-checkbox
              label='Bike'
              value='Bike'
              v-model='habits.travel.choices'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              label='Car'
              value='Car'
              v-model='habits.travel.choices'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              label='Train'
              value='Train'
              v-model='habits.travel.choices'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              label='Plane'
              value='Plane'
              v-model='habits.travel.choices'
              color='primary'
            />
          </v-list-item>
        </v-list>
        <v-text-field
          label='Describe:'
          v-model='habits.travel.details'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Pets in your home
        </div>
        <v-text-field label='Pets:' v-model='habits.pets'></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Diet
        </div>
        <v-radio-group v-model='habits.diet'>
          <v-radio label='Vegetarian' value='veg' color='primary' />
          <v-radio label='Non vegetarian' value='nonVeg' color='primary' />
        </v-radio-group>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Average hours spent
        </div>
        <v-text-field
          label='Home:'
          v-model='habits.timeSpent.home'
        ></v-text-field>
        <v-text-field
          label='Work/School:'
          v-model='habits.timeSpent.work'
        ></v-text-field>
        <v-text-field
          label='Others:'
          v-model='habits.timeSpent.other'
        ></v-text-field>
      </v-flex>

      <v-flex sm12>
        <br />
        <div class='headline font-weight-medium'>
          Other details
        </div>
        <br />
        <v-divider></v-divider>
        <br />
      </v-flex>

      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          House location
        </div>
        <v-list>
          <v-list-item v-for='i in tHouse' :key='i'>
            <v-checkbox
              :label='i'
              :value='i'
              v-model='others.house.choices'
              color='primary'
            />
          </v-list-item>
        </v-list>
        <v-text-field
          label='Age of the house:'
          v-model='others.house.age'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Sense of smell
        </div>
        <v-radio-group v-model='others.smell.choice'>
          <v-radio label='Good' value='aa' color='primary' />
          <v-radio label='Above average' value='a' color='primary' />
          <v-radio label='Below average' value='b' color='primary' />
          <v-radio label='Not smelling' value='n' color='primary' />
        </v-radio-group>
        <v-text-field
          :disabled='others.smell.choice !== "n"'
          label='Not smelling since:'
          v-model='others.smell.since'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Allergy symptoms are more at
        </div>
        <v-list>
          <v-list-item>
            <v-checkbox
              label='Home'
              value='Home'
              v-model='others.location.choices'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              label='Work'
              value='Work'
              v-model='others.location.choices'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              label='School'
              value='School'
              v-model='others.location.choices'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              label='In Bangalore'
              value='In Bangalore'
              v-model='others.location.choices'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              label='Outside Bangalore'
              value='Outside Bangalore'
              v-model='others.location.choices'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              label='Not sure'
              value='Not sure'
              v-model='others.location.choices'
              color='primary'
            />
          </v-list-item>
        </v-list>
        <v-text-field
          label='Describe:'
          v-model='others.location.details'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg3>
        <div class='subheading font-weight-medium'>
          Water leak in roof or walls
        </div>
        <v-radio-group v-model='others.leak'>
          <v-radio label='Yes' value='yes' color='primary' />
          <v-radio label='No' value='no' color='primary' />
        </v-radio-group>
      </v-flex>
      <v-flex xs12 sm6 lg3>
        <div class='subheading font-weight-medium'>
          Dust or insects in your surroundings
        </div>
        <v-radio-group v-model='others.insects'>
          <v-radio label='Yes' value='yes' color='primary' />
          <v-radio label='No' value='no' color='primary' />
        </v-radio-group>
      </v-flex>
      <v-flex xs12 sm6 lg3>
        <div class='subheading font-weight-medium'>
          Symptoms timing
        </div>
        <v-radio-group v-model='others.time'>
          <v-radio label='Day' value='Day' color='primary' />
          <v-radio label='Night' value='Night' color='primary' />
          <v-radio label='Both' value='Both' color='primary' />
        </v-radio-group>
      </v-flex>
      <v-flex xs12 sm6 lg3>
        <div class='subheading font-weight-medium'>
          Symptoms place
        </div>
        <v-list>
          <v-list-item
            ><v-checkbox
              v-model='others.place.choices'
              label='Indoor'
              value='Indoor'
              color='primary'
          /></v-list-item>
          <v-list-item
            ><v-checkbox
              v-model='others.place.choices'
              label='Outdoor'
              value='Outdoor'
              color='primary'
          /></v-list-item>
        </v-list>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Symptoms are worse with
        </div>
        <v-list>
          <v-list-item>
            <v-checkbox
              label='Food'
              value='Food'
              v-model='others.worse.choices'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              label='Work'
              value='Work'
              v-model='others.worse.choices'
              color='primary'
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              label='Drugs'
              value='Drugs'
              v-model='others.worse.choices'
              color='primary'
            />
          </v-list-item>
        </v-list>
        <v-text-field
          label='Others:'
          v-model='others.worse.others'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Symptoms month
        </div>
        <v-list>
          <v-list-item v-for='i in tMonths' :key='i'
            ><v-checkbox
              v-model='others.month.choices'
              :label='i'
              :value='i'
              color='primary'
          /></v-list-item>
        </v-list>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Exposed to
        </div>
        <v-list>
          <v-list-item v-for='i in tExposed' :key='i'
            ><v-checkbox
              v-model='others.exposed.choices'
              :label='i'
              :value='i'
              color='primary'
          /></v-list-item>
          <v-list-item>
            <v-text-field
              label='Others:'
              v-model='others.exposed.others'
            ></v-text-field>
          </v-list-item>
        </v-list>
      </v-flex>
      <v-flex xs12 sm6 lg12>
        <div class='subheading font-weight-medium'>
          Any observation in the living which influences your illness
        </div>
        <v-text-field
          label='Describe:'
          v-model='others.influence'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg12>
        <div class='subheading font-weight-medium'>
          In past years have you suffered from skin,nose,lung,throat or stomach
          disorders?
        </div>
        <v-text-field label='Describe:' v-model='others.past'></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg12>
        <div class='subheading font-weight-medium'>
          Any hobbies (walking, indoor/outdoor games, chat etc)
        </div>
        <v-text-field
          label='Hobbies:'
          v-model='others.hobbies.choices'
        ></v-text-field>
        <v-text-field
          label='Describe if these hobbies induce allergy symptoms:'
          v-model='others.hobbies.details'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg12>
        <div class='subheading font-weight-medium'>
          Does dust,fumes,weather, food or any other which induce allergy
          symptoms
        </div>
        <v-text-field
          label='Describe:'
          v-model='others.exposed.details'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg12>
        <div class='subheading font-weight-medium'>
          Do you dislike or avoid any food
        </div>
        <v-text-field
          label='Food:'
          v-model='others.food.choices'
        ></v-text-field>
        <v-text-field label='Why:' v-model='others.food.why'></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg12>
        <div class='subheading font-weight-medium'>
          Have you stopped eating dishes like sour, spicy, sweet, bitter etc
        </div>
        <v-text-field
          label='Dishes:'
          v-model='others.food.stopped'
        ></v-text-field>
        <v-text-field
          label='Describe:'
          v-model='others.food.describe'
        ></v-text-field>
      </v-flex>

      <v-flex sm12>
        <br />
        <div class='headline font-weight-medium'>
          For children
        </div>
        <br />
        <v-divider></v-divider>
        <br />
      </v-flex>

      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Problems during pre/post natal period or during delivery
        </div>
        <v-radio-group v-model='children.delivery'>
          <v-radio label='Yes' value='yes' color='primary' />
          <v-radio label='No' value='no' color='primary' />
        </v-radio-group>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Child had any colic
        </div>
        <v-radio-group v-model='children.colic'>
          <v-radio label='Yes' value='yes' color='primary' />
          <v-radio label='No' value='no' color='primary' />
        </v-radio-group>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Is the child is on full diet
        </div>
        <v-radio-group v-model='children.delivery'>
          <v-radio label='Yes' value='yes' color='primary' />
          <v-radio label='No' value='no' color='primary' />
        </v-radio-group>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Did child's mother drink milk while nursing
        </div>
        <v-radio-group v-model='children.nurse'>
          <v-radio label='Yes' value='yes' color='primary' />
          <v-radio label='No' value='no' color='primary' />
        </v-radio-group>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          Is/was the baby breast fed exclusively
        </div>
        <v-radio-group v-model='children.fed.choice'>
          <v-radio label='Yes' value='yes' color='primary' />
          <v-radio label='No' value='no' color='primary' />
        </v-radio-group>
        <v-text-field
          label='How long (months):'
          v-model='children.fed.months'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg4>
        <div class='subheading font-weight-medium'>
          If breast fed is not exclusive
        </div>
        <v-text-field
          label='Alternative food:'
          v-model='children.fedNo.choice'
        ></v-text-field>
        <v-text-field
          label='How long (months):'
          v-model='children.fedNo.month'
        ></v-text-field>
        <v-text-field
          label='Feeding solid food from:'
          v-model='children.fedNo.solidFrom'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg12>
        <div class='subheading font-weight-medium'>
          How many infections child has in
        </div>
        <v-text-field
          label='last 3 months:'
          v-model='children.infections.months3'
        ></v-text-field>
        <v-text-field
          label='last year:'
          v-model='children.infections.yr'
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 lg12>
        <div class='subheading font-weight-medium'>
          Additional information you like to inform in relation to allergy
          symptoms or any other disease
        </div>
        <v-text-field label='Info:' v-model='children.otherInfo'></v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      tMonths: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      tExposed: [
        'Dust',
        'Plants',
        'Flowers',
        'Pollution',
        'Climate change',
        'Scent',
        'Agarabatti smoke',
        'Medicine',
        'Working in house',
        'Office',
        'Travelling',
        'Outdoor activities'
      ],
      tHouse: ['City', 'Residential', 'Town', 'Rural', 'Farm', 'Industrial'],
      treatments: {
        drugAllergy: '',
        surgery: '',
        medication: { db: '', bp: '', th: '', others: '' },
        dental: { choices: [], others: '' }
      },
      family: {
        mother: { illness: '', surgery: '', allergies: '' },
        father: { illness: '', surgery: '', allergies: '' },
        grandparents: { illness: '', surgery: '', allergies: '' },
        siblings: { illness: '', surgery: '', allergies: '' },
        uncleAunt: { illness: '', surgery: '', allergies: '' }
      },
      habits: {
        smoking: { choices: [], daily: '', start: '', stopped: '' },
        alcohol: { choices: [], daily: '', start: '', stopped: '' },
        travel: { choices: [], details: '' },
        pets: '',
        diet: '',
        timeSpent: { home: '', work: '', other: '' }
      },
      others: {
        house: { choices: [], age: '' },
        smell: { choice: '', since: '' },
        location: { choices: [], details: '' },
        leak: '',
        insects: '',
        time: '',
        place: { choices: [] },
        worse: { choices: [], others: '' },
        month: { choices: [] },
        exposed: { choices: [], others: '', details: '' },
        influence: '',
        past: '',
        hobbies: { choices: '', details: '' },
        food: { choices: '', why: '', stopped: '', describe: '' }
      },
      children: {
        delivery: '',
        colic: '',
        diet: '',
        fed: { choice: '', months: '' },
        fedNo: { choice: '', month: '', solidFrom: '' },
        nurse: '',
        infections: { months3: '', yr: '' },
        otherInfo: ''
      }
    };
  },
  methods: {
    getTreatments() {
      return this.treatments;
    },
    getFamily() {
      return this.family;
    },
    getHabits() {
      return this.habits;
    },
    getOthers() {
      return this.others;
    },
    getChildren() {
      return this.children;
    },
    clear() {
      this.treatments = {
        drugAllergy: '',
        surgery: '',
        medication: { db: '', bp: '', th: '', others: '' },
        dental: { choices: [], others: '' }
      };
      this.family = {
        mother: { illness: '', surgery: '', allergies: '' },
        father: { illness: '', surgery: '', allergies: '' },
        grandparents: { illness: '', surgery: '', allergies: '' },
        siblings: { illness: '', surgery: '', allergies: '' },
        uncleAunt: { illness: '', surgery: '', allergies: '' }
      };
      this.habits = {
        smoking: { choices: [], daily: '', start: '', stopped: '' },
        alcohol: { choices: [], daily: '', start: '', stopped: '' },
        travel: { choices: [], details: '' },
        pets: '',
        diet: '',
        timeSpent: { home: '', work: '', other: '' }
      };
      this.others = {
        house: { choices: [], age: '' },
        smell: { choice: '', since: '' },
        location: { choices: [], details: '' },
        leak: '',
        insects: '',
        time: '',
        place: { choices: [] },
        worse: { choices: [], others: '' },
        month: { choices: [] },
        exposed: { choices: [], others: '', details: '' },
        influence: '',
        past: '',
        hobbies: { choices: '', details: '' },
        food: { choices: '', why: '', stopped: '', describe: '' }
      };
      this.children = {
        delivery: '',
        colic: '',
        diet: '',
        fed: { choice: '', months: '' },
        fedNo: { choice: '', month: '', solidFrom: '' },
        nurse: '',
        infections: { months3: '', yr: '' },
        otherInfo: ''
      };
    }
  }
};
</script>

<style scoped></style>
